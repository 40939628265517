import React, { useState } from "react";
import axios from "axios";
import { Input } from "@chakra-ui/react";
export default function SelectCities({ form, setForm }) {
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const fetchCities = async (searchTerm) => {
    if (!searchTerm || searchTerm.length === 0) {
      setSearchResults([]);
      return;
    }

    setIsLoading(true);
    try {
      const apiUrl = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
        searchTerm
      )}&format=jsonv2&addressdetails=1`;
      const response = await axios.get(apiUrl);
      const citiesData = response?.data;
      if (citiesData.length > 0) {
        setSearchResults(
          citiesData.filter((city) => city.address && city.address.city)
        );
      } else {
        console.log(`No data found for the city ${searchTerm}`);
      }
    } catch (error) {
      console.error("Error fetching data from OpenStreetMap API", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e) => {
    const inputValue = e.target.value;
    setForm((prevForm) => ({
      ...prevForm,
      city: inputValue,
    }));
    fetchCities(inputValue);
  };

  const handleSelect = (city) => {
    setForm((prevForm) => ({
      ...prevForm,
      cities: [city.address.city],
      city: "", // Clear input field after selection
    }));
    setSearchResults([]); // Hide dropdown after selection
  };

  return (
    <div style={{ position: "relative" }}>
      <Input
        type="text"
        placeholder="City"
        value={form.city}
        onChange={handleSearch}
        // style={{
        //   width: "100%",
        //   padding: "10px",
        //   borderRadius: "16px",
        //   backgroundColor: "#ECF1F4",
        //   border: "1px solid #ccc",
        // }}
      />

      {isLoading && <div>Loading...</div>}

      {!isLoading && searchResults.length > 0 && (
        <ul
          style={{
            position: "absolute",
            top: "100%",
            width: window.innerWidth > 769 ? "20vw" : "85vw",
            left: 0,
            right: 0,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            borderRadius: "8px",
            maxHeight: "200px",
            overflowY: "auto",
            zIndex: 1000,
            listStyle: "none",
            padding: "0",
            margin: "4px 0 0 0",
          }}
        >
          {searchResults.map((city, index) => (
            <li
              key={index}
              onClick={() => handleSelect(city)}
              style={{
                padding: "10px",
                cursor: "pointer",
              }}
            >
              {city.display_name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
