import React, { useState, useCallback, forwardRef } from "react";
import styles from "./style.module.css";
import { Button, Input, Box } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { message } from "antd";
import debounce from "lodash.debounce";
import LocationPicker from "./LocationPicker";
import { createNotificationData } from "../../../Redux/PushNotification/NotificationReducer";
import pushIcon from "../../../assets/icons/pushIcon.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { adjustedDate } from "../../../utils/utils";

const initialState = {
  name: "Partner",
  title: "",
  body: "",
  userType: "PARTNER",
  sendTo: "All",
  releaseDate: "",
  releaseTime: "",
  pushType: "PARTNER",
};

const CreateNotification = ({ setCreate, setType }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState(initialState);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const getMinDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 2);
    return date;
  };

  const minDate = getMinDate();

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Input
      value={value}
      onClick={onClick}
      placeholder="DD-MM-YYYY"
      style={{ cursor: "pointer" }}
      ref={ref}
    />
  ));
  const handleDateChange = (date) => {
    const originDate = adjustedDate(date);
    setSelectedDate(originDate);
    setForm({ ...form, releaseDate: originDate.toISOString().split("T")[0] });
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
    setForm({ ...form, releaseTime: time });
  };
  const filterTimes = (time) => {
    const hours = time.getHours();
    const minutes = time.getMinutes();
    return (hours === 11 && minutes === 0) || (hours === 18 && minutes === 0);
  };
  const addSelectedCity = (city) => {
    setSelectedCities([...selectedCities, city]);
  };
  const partnerId = JSON.parse(localStorage.getItem("userId"));
  const addData = () => {
    if (!form.title) {
      message.error("Title is required");
    } else if (!form.body) {
      message.error("Body is required");
    } else if (!selectedCities.length) {
      message.error("Cities are required");
    } else if (!form.releaseDate) {
      message.error("Release Date is required");
    } else if (!form.releaseTime) {
      message.error("Release Time is required");
    } else {
      setCreate(false);
      setType("Pending");
      dispatch(
        createNotificationData({
          ...form,
          cities: selectedCities.map((city) => city.value.description),
          partnerId,
        })
      ).then(() => {
        // setCreate(false);
        message.success("Notification created successfully");
      });
    }
  };

  return (
    <div className={styles.MainContainer}>
      <div className={styles.headDiv}></div>
      <div className={`${styles.container1} xl:mt-0`}>
        <p className="font-bold text-lg md:text-base">
          <a
            href="/Notification"
            className="cursor-pointer text-gray-500 hover:text-gray-800"
          >
            All Notifications
          </a>{" "}
          <span className="mx-2">&gt; </span>
          <span>Create New Notification</span>
        </p>
        <h1 className="font-bold text-2xl">Create New Notification</h1>

        <div className={styles.resetPassword}>
          <p>Title*</p>
          <Input
            placeholder="Title (max. 50 characters)"
            value={form.title}
            onChange={(e) => setForm({ ...form, title: e.target.value })}
          />
          <p>Body*</p>
          <Input
            placeholder="Add Body (max. 150 characters)"
            value={form.body}
            onChange={(e) => setForm({ ...form, body: e.target.value })}
          />

          <p className="mt-2">
            Users in the city listed below will receive this push notification:
          </p>
          <LocationPicker addSelectedCity={addSelectedCity} />

          <div className={styles.dateDiv}>
            <p>Release on</p>
            <Box>
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                customInput={<CustomInput />}
                minDate={minDate}
                dateFormat="yyyy-MM-dd"
                popperModifiers={[
                  {
                    name: "arrow",
                    options: {
                      padding: ({ popper, reference, placement }) => ({
                        right: Math.min(popper.width, reference.width) - 24,
                      }),
                    },
                  },
                ]}
                calendarStartDay={1}
              />
            </Box>
            {/* <Box>
              <DatePicker
                selected={selectedTime}
                onChange={handleTimeChange}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={60}
                filterTime={filterTimes}
                timeCaption="Time"
                dateFormat="h:mm aa"
                customInput={<CustomTimeInput />}
                className="custom-time"
                popperProps={{
                  style: {
                    width: "500px", // Adjust the width as needed
                    zIndex: 1000,
                  },
                }}
              />
            </Box> */}
          </div>
          <div className="my-2">
            <p className="mb-2">At this time</p>
            <div className={styles.timeDiv}>
              <div>
                <input
                  type="radio"
                  value="11:00 AM"
                  name="time"
                  className="h-auto"
                  checked={selectedTime === "11:00"}
                  onChange={() => handleTimeChange("11:00")}
                />{" "}
                11:00 AM
              </div>
              <div>
                {" "}
                <input
                  type="radio"
                  value="6:00 PM"
                  name="time"
                  checked={selectedTime === "18:00"}
                  onChange={() => handleTimeChange("18:00")}
                />{" "}
                6:00 PM
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.container2}>
        <div className={`${styles.btn} hidden md:flex`}>
          <Button
            colorScheme="black"
            variant="outline"
            w="127px"
            onClick={() => setCreate(false)}
          >
            Cancel
          </Button>
          <Button
            bg="black"
            color="#fff"
            variant="solid"
            w="127px"
            onClick={addData}
          >
            Save
          </Button>
        </div>
        <div className={styles.preview}>
          <p>Preview</p>
          <div className={styles.previewInner}>
            <span className={styles.spanIcon}>
              <img src={pushIcon} alt="" />
            </span>
            <div className="w-[75%] relative left-24">
              <h2 className="font-bold text-[#333333] text-2xl">
                {" "}
                {form.title}
              </h2>
              <p className="text-[#333333] text-lg"> {form.body}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.btn} flex justify-center mb-16 md:hidden`}>
        <Button
          bg="black"
          color="#fff"
          variant="solid"
          w="127px"
          onClick={addData}
        >
          Save
        </Button>
        <Button
          colorScheme="black"
          variant="outline"
          w="127px"
          onClick={() => setCreate(false)}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default CreateNotification;
