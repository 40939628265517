import React, { useEffect, useState, useRef } from "react";
import styles from "./styles.module.css";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
} from "@chakra-ui/react";

import { BiSolidLockAlt, BiSolidEditAlt } from "react-icons/bi";
import { Checkbox, message, Switch } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { userRequest } from "../../../requestMethod";
import euroSymbol from "../../../assets/euroSymbol.png";
import euroSymbolGray from "../../../assets/euroSymbolGray.png";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const EditProfile = () => {
  const [user, setData] = useState({});
  const userId = JSON.parse(localStorage.getItem("userId"));
  console.log("user", user);

  const [repeatSameTime, setRepeatSameTime] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [times, setTimes] = useState({
    Mon: { from: "", to: "", enabled: false },
    Tue: { from: "", to: "", enabled: false },
    Wed: { from: "", to: "", enabled: false },
    Thu: { from: "", to: "", enabled: false },
    Fri: { from: "", to: "", enabled: false },
    Sat: { from: "", to: "", enabled: false },
    Sun: { from: "", to: "", enabled: false },
  });
  console.log("times", times);
  const handleCheckboxChange = () => {
    if (!repeatSameTime) {
      // Check if at least one day has time set
      const hasAnyDayTime = Object.values(times).some(
        (day) => day.from && day.to
      );

      if (!hasAnyDayTime) {
        // Show modal if no time is set for any day
        setShowModal(true);
        return; // Stop further execution
      }

      // Apply the same time for all days
      const commonTime = {
        from: times.Mon.from,
        to: times.Mon.to,
        enabled: true,
      };
      setTimes({
        Mon: commonTime,
        Tue: commonTime,
        Wed: commonTime,
        Thu: commonTime,
        Fri: commonTime,
        Sat: commonTime,
        Sun: commonTime,
      });
    } else {
      // Clear times when unchecking the box
      setTimes({
        Mon: { from: "", to: "", enabled: false },
        Tue: { from: "", to: "", enabled: false },
        Wed: { from: "", to: "", enabled: false },
        Thu: { from: "", to: "", enabled: false },
        Fri: { from: "", to: "", enabled: false },
        Sat: { from: "", to: "", enabled: false },
        Sun: { from: "", to: "", enabled: false },
      });
    }

    setRepeatSameTime(!repeatSameTime); // Toggle checkbox
  };

  // Function to handle time change for any day
  // const handleTimeChange = (day, field, value) => {
  //   setTimes({
  //     ...times,
  //     [day]: { ...times[day], [field]: value, enabled: true },
  //   });

  //   // Remove the checkbox tick if any time is manually changed
  //   if (repeatSameTime) {
  //     setRepeatSameTime(false);
  //   }
  // };
  const onDragEnd = (result) => {
    // Check if the drag was dropped in a valid droppable area
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;
    // Reorder photos array based on drag result
    const reorderedPhotos = Array.from(user.photos);
    const [removed] = reorderedPhotos.splice(source.index, 1);
    reorderedPhotos.splice(destination.index, 0, removed);

    // Update state with the reordered photos array
    setData((prevUser) => ({
      ...prevUser,
      photos: reorderedPhotos,
    }));
  };

  const navigate = useNavigate();
  const getData = () => {
    userRequest
      .get(`/partner/business/getBusiness?_id=${userId}`)
      .then((res) => {
        setPriceLevel(res?.data?.data?.priceLevel);
        setData(res.data.data);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  const [priceLevel, setPriceLevel] = useState(user?.priceLevel || null);
  // Function to convert hh:mm AM/PM to HH:MM format
  const convertTo24HourFormat = (time) => {
    const [hours, minutes] = time?.split(":");
    const ampm = minutes?.slice(-2);
    const min = minutes?.slice(0, 2);
    let hr = parseInt(hours, 10);
    if (ampm?.toLowerCase() === "pm" && hr !== 12) hr += 12;
    if (ampm?.toLowerCase() === "am" && hr === 12) hr = 0;
    return `${hr?.toString().padStart(2, "0")}:${min}`;
  };
  console.log("user?.priceLevel", user?.priceLevel);
  // Initialize the times state with user.businessSchedule data
  // useEffect(() => {
  //   if (!!user?.businessSchedule?.length) {
  //     const initialTimes = {};
  //     user?.businessSchedule?.forEach((schedule) => {
  //       initialTimes[schedule?.day] = {
  //         from: convertTo24HourFormat(schedule?.from),
  //         to: convertTo24HourFormat(schedule?.to),
  //         enabled: schedule?.status,
  //       };
  //     });
  //     setTimes(initialTimes);
  //   }
  // }, [user?.businessSchedule]);
  // Function to handle time change for any day
  const handleTimeChange = (day, field, value) => {
    setTimes({
      ...times,
      [day]: { ...times[day], [field]: value, enabled: true },
    });

    // Remove the checkbox tick if any time is manually changed
    if (repeatSameTime) {
      setRepeatSameTime(false);
    }
  };

  // Initialize the times state with user.businessSchedule data
  useEffect(() => {
    console.log("user?.businessSchedule", user?.businessSchedule);
    if (!!user?.businessSchedule?.length) {
      const initialTimes = {};
      user?.businessSchedule?.forEach((schedule) => {
        initialTimes[schedule?.day] = {
          from: schedule?.from, // Assuming `from` is already in HH:MM format
          to: schedule?.to, // Assuming `to` is already in HH:MM format
          enabled: schedule?.status,
        };
      });
      setTimes(initialTimes);
      console.log("initialTimes", initialTimes);
    }
  }, [user?.businessSchedule]);
  const inputRef = useRef(null);
  console.log("priceLevel", priceLevel);
  const handleInputClick = () => {
    inputRef.current && inputRef.current.focus();
  };
  const handleSwitchChange = (day, value) => {
    setTimes((prev) => ({
      ...prev,
      [day]: { ...prev[day], enabled: value },
    }));
  };
  const handleSaveChanges = () => {
    const updatedUser = {
      ...user,
      businessSchedule: times,
      priceLevel: priceLevel || user?.priceLevel,
    };
    user.businessSchedule = times;
    console.log("updatedUser", updatedUser);
    userRequest
      .put(`partner/user/updateUser`, updatedUser)
      .then((res) => {
        console.log("Updated Data: ", res.data.data);
        message.success("Partner profile updated successfully");
        navigate("/Profile");
      })
      .catch((error) => {
        console.error("Error updating profile: ", error);
      });
  };
  const renderPriceLevels = () => {
    const levels = [1, 2, 3]; // Define the levels dynamically
    return levels.map((level) => (
      <img
        key={level}
        src={priceLevel === level ? euroSymbol : euroSymbolGray}
        alt={`Price Level ${level}`}
        className="cursor-pointer"
        onClick={() => setPriceLevel(level)}
      />
    ));
  };
  return (
    <div className={styles.MainContainer}>
      <div className={styles.headDiv}></div>
      <div className={styles.firstDiv}>
        <div className={`${styles.head} mt-28 md:mt-10 lg:mt-0`}>
          <p className="font-bold text-xl md:text-base">
            <Link
              to="/Profile"
              className="cursor-pointer text-gray-500 hover:text-gray-800"
            >
              My Profile
            </Link>{" "}
            <span className="mx-2">&gt; </span>
            <span>Edit Profile</span>
          </p>
          <h1 className="font-bold text-3xl pt-5">Edit Profile</h1>
        </div>

        <div className={`${styles.btn} hidden md:flex`}>
          <Button variant="outline" onClick={() => navigate("/Profile")}>
            Cancel
          </Button>
          <Button bg="black" color="#fff" onClick={handleSaveChanges}>
            {" "}
            Save Changes
          </Button>
        </div>
      </div>
      <div className={styles.secondDiv}>
        <div className={styles.details}>
          <span className={styles.title}>
            Name* <BiSolidLockAlt />
          </span>
          <div className={styles.name}>
            <p>{user.name}</p>
            {/* <p className={styles.verified}>Verified</p> */}
          </div>
          <p className={styles.title}>
            Category Type*
            <BiSolidLockAlt />
          </p>
          <div className={styles.name}>
            <p>{user.category}</p>
            {/* <p className={styles.verified}>Verified</p> */}
          </div>
          <p className={styles.title}>
            Location <BiSolidLockAlt />
          </p>
          <p className="text-lg mb-2">{user.address}</p>
          <p className={styles.title}>
            Price <BiSolidEditAlt />
          </p>
          <div className="flex items-center gap-1 text-lg mb-2">
            <div className="flex w-6 h-6 gap-2">{renderPriceLevels()}</div>
            {/* <input
              // value={price}
              defaultValue={price || user?.price}
              onChange={(el) => setPrice(el.target.value)}
              placeholder="Add Price"
              className=""
            /> */}
          </div>
          <p className="mb-3 text-xl font-semibold">
            What are your opening hours:
          </p>
          <div className={styles.timeDiv}>
            <table className="h-[400px] w-[330px] block">
              {Object.keys(times).map((day) => (
                <tr key={day} className="flex items-center mb-5 justify-around">
                  <td style={{ whiteSpace: "nowrap" }} className="w-[14%]">
                    {day} &nbsp;
                  </td>
                  <td
                    style={{ whiteSpace: "nowrap" }}
                    className="w-[15%] md:[30%]"
                  >
                    <Switch
                      checked={times[day].enabled}
                      onChange={() =>
                        handleSwitchChange(day, !times[day].enabled)
                      }
                    />{" "}
                    &nbsp;&nbsp;
                  </td>
                  <td
                    style={{ whiteSpace: "nowrap" }}
                    className="flex w-[30%] md:w-[160px]"
                  >
                    <Input
                      type="time"
                      placeholder="from"
                      className="mr-4"
                      value={times[day].from}
                      onChange={(e) =>
                        handleTimeChange(day, "from", e.target.value)
                      }
                    />
                    &nbsp;
                  </td>
                  <td className="flex w-[25%] md:w-[140px]">
                    <Input
                      type="time"
                      placeholder="To"
                      value={times[day].to}
                      onChange={(e) =>
                        handleTimeChange(day, "to", e.target.value)
                      }
                    />
                  </td>
                </tr>
              ))}
            </table>
            {/* <table className="h-[400px] w-[330px] block">
              {Object.keys(times).map((day) => (
                <tr key={day} className="flex items-center mb-5 justify-around">
                  <td style={{ whiteSpace: "nowrap" }} className="w-[14%]">
                    {day} &nbsp;
                  </td>
                  <td
                    style={{ whiteSpace: "nowrap" }}
                    className="w-[15%] md:[30%]"
                  >
                    <Switch
                      checked={times[day].enabled}
                      onChange={() =>
                        handleSwitchChange(day, !times[day].enabled)
                      }
                    />{" "}
                    &nbsp;&nbsp;
                  </td>
                  <td
                    style={{ whiteSpace: "nowrap" }}
                    className="flex w-[30%] md:w-[160px]"
                  >
                    <Input
                      type="time"
                      placeholder="from"
                      className="mr-4"
                      value={times[day].from}
                      onChange={(e) =>
                        handleTimeChange(day, "from", e.target.value)
                      }
                      // disabled={!times[day].enabled}
                    />
                    &nbsp;
                  </td>
                  <td className="flex w-[25%] md:w-[140px]">
                    <Input
                      type="time"
                      placeholder="To"
                      value={times[day].to}
                      onChange={(e) =>
                        handleTimeChange(day, "to", e.target.value)
                      }
                      // disabled={!times[day].enabled}
                    />
                  </td>
                </tr>
              ))}
            </table> */}
          </div>
          <p className="my-3">
            <Checkbox checked={repeatSameTime} onChange={handleCheckboxChange}>
              Repeat same time for everyday
            </Checkbox>
          </p>
        </div>

        <div className={styles.photoDiv}>
          <p className={styles.title}>Hold and drag photos to reorder</p>
          <p>{user?.bio}</p>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="photoList" direction="horizontal">
              {(provided) => (
                <>
                  <div
                    // className={styles.photos}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <div className="flex gap-2 mb-2">
                      <div>
                        {user?.photos?.length > 0 && (
                          <Draggable draggableId={user.photos[0]} index={0}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={styles.mainPhoto}
                              >
                                <img
                                  src={user?.photos[0]}
                                  alt="Photo 0"
                                  loading="lazy"
                                />
                                <div className={styles.photoIndex}>1</div>
                              </div>
                            )}
                          </Draggable>
                        )}
                        {provided.placeholder}
                      </div>
                      <div className={styles.subDiv1}>
                        {user?.photos?.slice(1, 3).map((photo, index) => (
                          <Draggable
                            key={`photo-${index + 1}`}
                            draggableId={`photo-${index + 1}`}
                            index={index + 1}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={styles.subPhoto}
                              >
                                <img src={photo} alt={`Photo ${index + 1}`} />
                                <div className={styles.photoIndex}>
                                  {index + 2}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Droppable>
            <Droppable droppableId="subDiv2" direction="horizontal">
              {(provided) => (
                <div
                  className={styles.subDiv2}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {user?.photos?.length > 3 && (
                    <Draggable draggableId={`photo-3`} index={3}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={styles.subPhoto}
                        >
                          <img src={user.photos[3]} alt={`Photo 3`} />
                          <div className={styles.photoIndex}>4</div>
                        </div>
                      )}
                    </Draggable>
                  )}
                  {user?.photos?.length > 4 && (
                    <Draggable draggableId={`photo-4`} index={4}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={styles.subPhoto}
                        >
                          <img src={user.photos[4]} alt={`Photo 4`} />
                          <div className={styles.photoIndex}>5</div>
                        </div>
                      )}
                    </Draggable>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <Droppable droppableId="subDiv3" direction="horizontal">
              {(provided) => (
                <div
                  className={styles.subDiv2}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {user?.photos?.length > 5 && (
                    <Draggable draggableId={`photo-5`} index={5}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={styles.subPhoto}
                        >
                          <img src={user.photos[5]} alt={`Photo 5`} />
                          <div className={styles.photoIndex}>6</div>
                        </div>
                      )}
                    </Draggable>
                  )}
                  {user?.photos?.length > 6 && (
                    <Draggable draggableId={`photo-6`} index={6}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={styles.subPhoto}
                        >
                          <img src={user.photos[6]} alt={`Photo 6`} />
                          <div className={styles.photoIndex}>7</div>
                        </div>
                      )}
                    </Draggable>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      <div className={`${styles.btn} flex justify-center mb-16 md:hidden`}>
        <Button variant="outline" onClick={() => navigate("/Profile")}>
          Cancel
        </Button>
        <Button bg="black" color="#fff" onClick={handleSaveChanges}>
          {" "}
          Save Changes
        </Button>
      </div>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Please enter the opening hours for 1 day at least.
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Button onClick={() => setShowModal(false)}>Close</Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default EditProfile;
