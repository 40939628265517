import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import styles from "./styles.module.css";
import check from "../../../assets/for_this/Check.svg";
import info from "../../../assets/for_this/info.svg";
import { publicRequest, userRequest } from "../../../requestMethod";
import { formatDate } from "../../../utils/utils";
import { message, Spin } from "antd";
import CancelModal from "./CancelModal";
import { useDisclosure } from "@chakra-ui/react";
import { Modal, Button } from "antd";
const ActivePlan = () => {
  const [packages, setPackages] = useState([]);
  const [subscription, setSubscription] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selected, setSelected] = useState(undefined);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const benefits = [
    "Guaranteed place on our map",
    "Preferred representation",
    "Expand your visibility",
    "Stamp card advantage",
    "In app advertising",
    "Personalize your profile",
    "Send push notification",
  ];
  const basicPlanBenefits = [
    "In app advertising",
    "Personalize your profile",
    "Send push notification",
  ];
  const toolTipContent = [
    "Your establishment will be prominently displayed on our map, ensuring visibility to NETME users in your area.",
    "As a partner, your listing will receive priority placement and enhanced visibility, increasing the chances of attracting potential customers.",
    "NETME provides an opportunity for your establishment to reach a broader audience and expand your customer base.",
    "Offer exclusive stamp card rewards to NETME users, encouraging repeat visits and fostering customer loyalty.",
    "Utilize in-app advertising to showcase special promotions, events, or offers to our engaged user community.",
    "Customize your establishment's profile with photos, descriptions, and additional details to make it more appealing and informative to potential customers.",
    "Send targeted push notifications to NETME users, keeping them informed about special offers, events, or updates from your establishment.",
  ];
  const businessAndEnterprisePlan = [
    "In app advertising",
    "Send push notification",
  ];
  const fetchData = async () => {
    try {
      const response = await publicRequest.get("/api/package");
      const subscription = await userRequest.get(
        "/api/partnerSubscription/getAPartnerSubscription"
      );
      console.log("subscription", subscription);
      const allPackages = response.data.data.filter(
        (d) => d.userType === "PARTNER"
      );
      setPackages(allPackages);
      setSelected(allPackages?.[0]);
      setSubscription(subscription.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handleCancelSubscription = async () => {
    onOpen();
    // if (!!subscription?.isCancel) {
    //   message.error("Already canceled");
    // } else {
    //   const cancelSubs = await userRequest.put(
    //     "/api/partnerSubscription/cancelSubscription"
    //   );
    //   console.log("cancelSubs", cancelSubs);
    //   if (cancelSubs?.statusText === "OK") {
    //     fetchData();
    //     message.success("Active plan cancelled successfully");
    //   }
    // }
  };
  const showUpgradeModal = (planName, type) => {
    setSelectedPlan(planName);
    setType(type);
    setIsModalVisible(true);
  };

  const handleUpgrade = async () => {
    setIsLoading(true);
    try {
      const plans = packages.find((plan) => plan.planName === selectedPlan);
      const successUrl = new URL("/Settings", window.location.href);
      const upgradePlan = await userRequest.get(
        `/api/partnerSubscription/getPartnerSubscriptionPaymentLink/${plans?._id}?currentSubsciptionId=${subscription?._id}&purchaseType=UPGRADE&successUrl=${successUrl}`
      );
      console.log("upgradePlan", upgradePlan);
      if (upgradePlan?.data?.url) {
        await fetchData();
        window.location.href = upgradePlan?.data?.url;
        message.success("Upgrade plan successfully initiated");
      } else if (upgradePlan?.statusText === "OK") {
        // const iAmIn = await fetchData();
        // console.log("iAmIn", iAmIn);
        window.location.reload();
        message.success("Upgrade plan successfully initiated");
      } else {
        throw new Error("Failed to fetch upgrade URL");
      }
    } catch (error) {
      message.error("Upgrade failed. Please try again.");
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsModalVisible(false);
    }
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  // const handleUpdradeSubscription = async (planName) => {
  //   console.log("upgradeSubs");
  //   const plans = packages.find((plan) => plan.planName === planName);
  //   console.log("plans", plans);
  //   const successUrl = new URL("/Settings", window.location.href);
  //   const upgradePlan = await userRequest.get(
  //     `/api/partnerSubscription/getPartnerSubscriptionPaymentLink/${plans?._id}?currentSubsciptionId=${subscription?._id}&purchaseType=UPGRADE&successUrl=${successUrl}`
  //   );
  //   if (upgradePlan?.data?.url) {
  //     window.location.href = upgradePlan?.data?.url;
  //   }
  //   fetchData();
  //   message.success("Upgrade Plan successfully");
  //   console.log("upgradePlan", upgradePlan?.data);
  // };
  console.log("packages", packages);
  console.log("subscription", subscription);
  return (
    <div className={styles.container}>
      {/* <p
        style={{
          color: "red",
          marginTop: "20px",
        }}
      >
        Expires on {formatDate(subscription?.expireAt)}
      </p> */}
      <div className={`${styles.container1}`}>
        {subscription?.subscriptionId?.planName === "STARTER" && (
          <div className={styles.topBtn}>Current active plan</div>
        )}
        <h5 className="mt-3">Starter</h5>
        <div className={styles.spanDiv}>
          {benefits.map((benefit, index) => (
            <span className={styles.Span} key={index}>
              <span className={styles.innerSpan}>
                {basicPlanBenefits.includes(benefit) ? (
                  <div className="ml-1.5 mr-0.5 mt-2 border-t-2 border-[#BCBCBC] w-2"></div>
                ) : (
                  <img src={check} alt="" />
                )}
                {benefit}
              </span>
              <img
                src={info}
                alt=""
                className="cursor-pointer"
                data-tooltip-id={`tooltip-${index}`}
                data-tooltip-content={toolTipContent[index]}
              />
              <Tooltip
                id={`tooltip-${index}`}
                place="right"
                effect="solid"
                className={`${styles.customTooltip} max-w-[200px] text-wrap z-10 relative`}
              />
            </span>
          ))}
          {subscription?.subscriptionId?.planName === "STARTER" ? (
            <>
              <CancelModal
                isOpen={isOpen}
                onClose={onClose}
                subscription={subscription}
                fetchData={fetchData}
              />
              <button
                disabled={!!subscription?.isCancel}
                onClick={() => handleCancelSubscription()}
                className={`w-full bg-[#7a7777]
                     ${styles.submit}`}
              >
                {!!subscription?.isCancel
                  ? "Already canceld"
                  : "Cancel current subscription "}{" "}
                {packages[0]?.price + " "} €
              </button>
            </>
          ) : (
            <button
              onClick={() =>
                showUpgradeModal(
                  "STARTER",
                  subscription?.subscriptionId?.planName === "BUSINESS" ||
                    "ENTERPRISE"
                    ? "Downgrade"
                    : "Upgrade"
                )
              }
              className={`w-full
                bg-[#333333]
              ${styles.submit}`}
            >
              {subscription?.subscriptionId?.planName === "BUSINESS" ||
              "ENTERPRISE"
                ? "Downgrade"
                : "Upgrade"}{" "}
              from {packages[0]?.price}€
            </button>
          )}
        </div>
      </div>

      <p>Upgrade to</p>

      <div className={styles.container2}>
        <div className={styles.container1}>
          {subscription?.subscriptionId?.planName === "BUSINESS" && (
            <div className={styles.topBtn}>Current active plan</div>
          )}
          <h5 className="mt-3">Business</h5>
          <div className={styles.spanDiv}>
            {benefits.map((benefit, index) => (
              <span className={styles.Span} key={index}>
                <span className={styles.innerSpan}>
                  {businessAndEnterprisePlan.includes(benefit) ? (
                    <div className="ml-1.5 mr-0.5 mt-2 border-t-2 border-[#BCBCBC] w-2"></div>
                  ) : (
                    <img src={check} alt="" />
                  )}
                  {benefit}
                </span>
                <img
                  src={info}
                  alt=""
                  className="cursor-pointer"
                  data-tooltip-id={`tooltip-${index}-b`}
                  data-tooltip-content={toolTipContent[index]}
                />
                <Tooltip
                  id={`tooltip-${index}-b`}
                  place="right"
                  effect="solid"
                  className={`${styles.customTooltip} max-w-[200px] text-wrap z-10 relative`}
                />
              </span>
            ))}
            {subscription?.subscriptionId?.planName === "BUSINESS" ? (
              <>
                <CancelModal
                  isOpen={isOpen}
                  onClose={onClose}
                  subscription={subscription}
                  fetchData={fetchData}
                />
                <button
                  disabled={!!subscription?.isCancel}
                  onClick={() => handleCancelSubscription()}
                  className={`w-full bg-[#7a7777]
           ${styles.submit}`}
                >
                  {!!subscription?.isCancel
                    ? "Already canceld"
                    : "Cancel current subscription "}{" "}
                  {packages[1]?.price + " "} €
                </button>
              </>
            ) : (
              <button
                onClick={() =>
                  showUpgradeModal(
                    "BUSINESS",
                    subscription?.subscriptionId?.planName === "ENTERPRISE"
                      ? "Downgrade"
                      : "Upgrade"
                  )
                }
                className={`w-full
                bg-[#333333]
              ${styles.submit}`}
              >
                {subscription?.subscriptionId?.planName === "ENTERPRISE"
                  ? "Downgrade"
                  : "Upgrade"}{" "}
                from {packages[1]?.price}€
              </button>
            )}
          </div>
        </div>
        <div className={styles.container1}>
          {subscription?.subscriptionId?.planName === "ENTERPRISE" && (
            <div className={styles.topBtn}>Current active plan</div>
          )}
          <h5 className="mt-3">Enterprise</h5>
          <div className={styles.spanDiv}>
            {benefits.map((benefit, index) => (
              <span className={styles.Span} key={index}>
                <span className={styles.innerSpan}>
                  {businessAndEnterprisePlan.includes(benefit) ? (
                    <div className=" w-5 border-[#BCBCBC] text-[7px]">
                      <div>1x/</div>
                      <div>month</div>
                    </div>
                  ) : (
                    <img src={check} alt="" />
                  )}
                  {benefit}
                </span>
                <img
                  src={info}
                  alt=""
                  className="cursor-pointer"
                  data-tooltip-id={`tooltip-${index}-e`}
                  data-tooltip-content={toolTipContent[index]}
                />
                <Tooltip
                  id={`tooltip-${index}-e`}
                  place="right"
                  effect="solid"
                  className={`${styles.customTooltip} max-w-[200px] text-wrap z-10 relative`}
                />
              </span>
            ))}
            {subscription?.subscriptionId?.planName === "ENTERPRISE" ? (
              <>
                <CancelModal
                  isOpen={isOpen}
                  onClose={onClose}
                  subscription={subscription}
                  fetchData={fetchData}
                />
                <button
                  disabled={!!subscription?.isCancel}
                  onClick={() => handleCancelSubscription()}
                  className={`w-full bg-[#7a7777]
                       ${styles.submit}`}
                >
                  {!!subscription?.isCancel
                    ? "Already canceld"
                    : "Cancel current subscription "}{" "}
                  {packages[2]?.price + " "} €
                </button>
              </>
            ) : (
              <button
                onClick={() => showUpgradeModal("ENTERPRISE", "Upgrade")}
                className={`w-full
                bg-[#333333]
              ${styles.submit}`}
              >
                Upgrade from {packages[2]?.price}€
              </button>
            )}
          </div>
        </div>
      </div>
      <Modal
        title={`${type} Subscription`}
        visible={isModalVisible}
        onOk={handleUpgrade}
        onCancel={handleCancel}
        confirmLoading={isLoading}
        okText={`Yes, ${type}`}
        cancelText="No"
        okButtonProps={{
          style: {
            backgroundColor: "#333333",
            borderColor: "#333333",
            color: "#fff",
          },
        }}
      >
        <p>
          Are you sure you want to {type} to the {selectedPlan} plan?
        </p>
        {isLoading && <Spin />}
      </Modal>
    </div>
  );
};

export default ActivePlan;
