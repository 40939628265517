import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
} from "@chakra-ui/react";
import { userRequest } from "../../../requestMethod";
import { message } from "antd";
import {
  calculateNextCancellationDate,
  formatDate,
  getEffectiveEndDate,
} from "../../../utils/utils";

const CancelModal = ({ isOpen, onClose, subscription, fetchData }) => {
  console.log("subscription", subscription);
  const currentPeriodEnd = subscription?.expireAt;
  const currentPeriodStart = subscription?.startAt;

  // Convert the current period end from seconds to a Date object
  const currentStartDate = new Date(currentPeriodStart);
  const currentEndDate = new Date(currentPeriodEnd);

  // Calculate the next possible cancellation date from the start date
  const nextCancellationDate = calculateNextCancellationDate(currentStartDate);

  const now = new Date();
  let effectiveCancellationDate;

  // Check if the cancellation notice period is respected
  if (now < nextCancellationDate) {
    // If within the notice period, cancellation will take effect at the current period end
    effectiveCancellationDate = currentEndDate;
  } else {
    // Otherwise, extend the cancellation to the next valid end date
    effectiveCancellationDate = getEffectiveEndDate(currentEndDate);
  }
  const handleCancelSubscription = async () => {
    if (!!subscription?.isCancel) {
      message.error("Already canceled");
    } else {
      const cancelSubs = await userRequest.put(
        "/api/partnerSubscription/cancelSubscription"
      );
      console.log("cancelSubs", cancelSubs);
      if (cancelSubs?.statusText === "OK") {
        fetchData();
        message.success("Active plan cancelled successfully");
        onClose();
      }
    }
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"lg"}>
        {/* <ModalOverlay /> */}
        <ModalContent
          boxShadow="dark-lg"
          borderRadius="24px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          right="-450px"
          top="100px"
        >
          <ModalHeader marginTop="46px" textAlign="center">
            🚨 Are you sure you want to cancel?
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            display="flex"
            justifyContent="space-around"
            flexDirection="column"
            gap="20px"
            alignItems="center"
          >
            <div className="flex flex-col items-center gap-4">
              <Text as="b">We’re sad to see you go! 😢</Text>
              <p>
                Your membership will remain active until{" "}
                <Text as="b">
                  {subscription?.expireAt
                    ? formatDate(effectiveCancellationDate)
                    : "October 18, 2024"}{" "}
                </Text>
              </p>
              <p>That’s one last month to enjoy all the benefits!</p>
            </div>
            <button
              onClick={handleCancelSubscription}
              className="border border-black py-3 px-8 rounded-full mb-1"
            >
              Yes, Cancel My Membership 🛑
            </button>
            <button
              onClick={onClose}
              className="bg-[#333333] text-white py-3 px-10 rounded-full mb-5"
            >
              No, Keep My Membership 👍
            </button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CancelModal;
