import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { userRequest } from "../../../../../requestMethod";
import styles from "./styles.module.css";
import suggestionList from "../../../../../assets//suggestionList.png";
import pizza from "../../../../../assets/pizza.jpg";
import image1 from "../../../../../assets/image1.png";
import location1 from "../../../../../assets/location1.png";
import stars from "../../../../../assets/stars.png";
import inFeedList from "../../../../../assets/inFeedList.png";
import Ad from "../../../../../assets/ad.png";
import Cross from "../../../../../assets/cross.png";
import { Button, Modal } from "antd";
const RequestedAds = ({ id, onClose, isModalVisible }) => {
  // const { id } = useParams();
  const [adData, setAdData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialPhoto, setInitialPhoto] = useState("");
  const [error, setError] = useState(null);
  const userId = JSON.parse(localStorage.getItem("userId"));
  const getData = () => {
    userRequest
      .get(`/partner/business/getBusiness?_id=${userId}`)
      .then((res) => {
        setInitialPhoto(res?.data?.data?.photos?.[0]);
        // setInitialPhoto(data?.photos?.[0]);
        console.log("Fetched Data: ", res.data.data);
      });
  };

  useEffect(() => {
    const fetchAdData = async () => {
      try {
        const response = await userRequest.get(
          `/partner/ads/getSingleRequestedAds/${id}`
        );
        const singleAd = response.data.data;
        setAdData(singleAd);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    getData();
    fetchAdData();
  }, [id, isModalVisible]);
  console.log("initialPhoto", initialPhoto);
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  console.log("adData", adData);

  const formattedCities = adData?.cities?.join(", ") || adData?.cities;

  return (
    <Modal open={isModalVisible} onCancel={onClose} footer={[]}>
      <p className="mb-10">
        This ad will be displayed in: [ {formattedCities} ]
      </p>
      <div>
        {adData?.adType === "Suggestion" && (
          <div className={styles.imageContainer}>
            <img
              src={suggestionList}
              alt="Suggestion List"
              className={styles.suggestionImage}
            />
            <div className={styles.imageInside}>
              <div className={styles.previewText}>
                <p>Here are some </p>
                <p>suggestions for you !</p>
                <p>Discover for each place.</p>
              </div>
              <div className={styles.partnerSug}>
                <div className={styles.previewInputImage}>
                  <img src={adData?.image || initialPhoto} alt="Uploaded" />
                </div>
                <div className={styles.previewAdDetails}>
                  <div className={styles.adPreviewTitle}>
                    <p>{adData?.name}</p>
                  </div>

                  <div className={styles.locations}>
                    <img src={location1} alt="location1" />
                    <p>2km</p>
                  </div>
                </div>
                <img src={stars} alt="location1" className={styles.stars} />
              </div>
              <div style={{ marginTop: "10px" }}>
                <div className={styles.previewInputImage}>
                  <img src={pizza} alt="1" />
                </div>
                <div className={styles.previewAdDetails}>
                  <div className={styles.adPreviewTitle}>
                    <p>{adData?.name}</p>
                  </div>

                  <div className={styles.locations}>
                    <img src={location1} alt="location1" />
                    <p>2km</p>
                  </div>
                </div>
                <img src={stars} alt="location1" className={styles.stars} />
              </div>
            </div>
          </div>
        )}
        {adData?.adType === "Feed" && (
          <div className={styles.imageContainer1}>
            <img
              src={inFeedList}
              alt="inFeedList"
              className={styles.suggestionImage1}
            />
            <div className={styles.imageInside1}>
              <div>
                <div className={styles.previewInputImage1}>
                  <div className={styles.div1}>
                    <img
                      src={Ad}
                      alt="1"
                      style={{
                        width: "30px",
                        position: "absolute",
                        top: "4%",
                        right: "95%",
                      }}
                    />
                    <img
                      src={Cross}
                      alt="1"
                      style={{
                        width: "30px",
                        position: "absolute",
                        left: "95%",
                        top: "4%",
                      }}
                    />
                    <img
                      src={adData?.image || initialPhoto}
                      alt="1"
                      className="rounded-[25px] object-cover h-[150px] "
                    />

                    <div className={styles.div2}>
                      <p className={styles.paragraph}>{adData?.title}</p>
                      <p className={styles.paragraph1}>{adData?.body}</p>
                    </div>
                    <div className={styles.div3}>
                      <button className={styles.buttons1}>
                        All Localities
                      </button>
                      <button className={styles.buttons2}>More Details</button>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "10px" }}></div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default RequestedAds;
