import React from "react";
import ads from "../../assets/ads.png";
import inbox from "../../assets/inbox.png";
import push from "../../assets/push.png";
import { userRequest } from "../../requestMethod";
import { formatTime } from "../../utils/utils";
const ShowNotification = ({ setNotificationHandler, userData }) => {
  const updatePartnerNotification = async (id) => {
    const updatedData = {
      notificationId: id,
      seen: true,
    };
    userRequest
      .put("partner/business/updatePartnerNotification", updatedData)

      .then((response) => {
        // message.success("Profile updated successfully");
        console.log("res", response);
      })
      .catch((error) => {
        // message.error("Error updating profile", error);
      });
  };
  return (
    <div className="absolute right-0 mt-4 w-80 bg-white border border-gray-300 rounded-lg shadow-lg z-50">
      {/* Header with Close Button */}
      <div className="flex justify-between items-center p-4 border-b border-gray-200">
        <h2 className="text-lg font-semibold text-gray-700">Notifications</h2>
        <button
          onClick={() => setNotificationHandler(false)}
          className="text-gray-500 hover:text-gray-700"
        >
          &times;
        </button>
      </div>

      {/* Notifications List */}
      <div className="p-4 space-y-3">
        {/* Example notification */}
        {/* <div className="flex items-start space-x-3">
          <div className="w-8 h-8 bg-blue-500 rounded-full flex items-center justify-center text-white">
N
          </div>
          <div className="flex-1">
            <p className="text-sm font-medium text-gray-800">
              New message received
            </p>
            <p className="text-xs text-gray-500">2 minutes ago</p>
          </div>
        </div> */}

        {userData?.notifications?.map((notification) => (
          <div
            key={notification?._id}
            className="flex items-start space-x-3"
            onClick={() => updatePartnerNotification(notification?._id)}
          >
            <div className="w-8 h-8 rounded-full flex items-center justify-center text-white">
              {/* Replace with an icon */}
              {notification.type === "ADS" && <img src={ads} alt="ads" />}
              {notification.type === "PUSH" && <img src={push} alt="push" />}
              {notification.type === "INBOX" && <img src={inbox} alt="inbox" />}
            </div>
            <div className="flex-1">
              <p
                className={`text-sm text-gray-800  ${
                  !notification.seen ? "font-bold" : "font-medium"
                }`}
              >
                {notification.title}
              </p>
              <p className="text-sm font-medium text-gray-800">
                {notification.body}
              </p>
              <p className="text-xs text-gray-500">
                {formatTime(notification?.createdAt)}
              </p>
            </div>
          </div>
        ))}

        {/* Add more notifications as needed */}
      </div>
    </div>
  );
};

export default ShowNotification;
